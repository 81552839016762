.backdrop__filter {
    position: fixed;
    z-index: 70;
    background-color: rgba(0, 0, 0, 0.5);
    width: 100%;
    height: 100vh;
    top: 0;
    left: 0;
}


.checkbox:checked {
    border: none;
}
.checkbox:checked + .check-icon {
    display: flex;
}