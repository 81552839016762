@import 'tailwindcss/base';
@import 'tailwindcss/components';
@import 'tailwindcss/utilities';

:root {
  --primary-color: #53D1CE;
  --secondary-color: #2eaaa7;
}
.btn-color-primary {
  background-color: var(--primary-color);
}
.btn-color-primary:hover {
  background-color: var(--secondary-color);
}

.card-btn-color-primary:hover {
  background-color: var(--primary-color);
}

.modal-btn-color-primary:hover {
  background-color: var(--primary-color);
  color: white;
}

.icon-hover-primary:hover {
  background-color: var(--primary-color);
}

.border-primary-color {
  border-color: var(--primary-color);
}

.border-primary-color:hover {
  border-color: var(--secondary-color);
}

.text-primary-color {
  color: var(--primary-color);
}

.text-primary-no-hover {
  color: var(--primary-color);
}

.text-primary-color:hover {
  color: var(--secondary-color);
}

.default-text-primary-color {
  color: black;
}

.default-text-primary-color:hover {
  color: var(--secondary-color);
}

.focus-border-primary:focus {
  border-color: var(--primary-color);
}




@layer utilities {
  @layer responsive {
  /* Hide scrollbar for Chrome, Safari and Opera */
  .no-scrollbar::-webkit-scrollbar {
    display: none;
  }
  /* Hide scrollbar for IE, Edge and Firefox */
  .no-scrollbar {
    -ms-overflow-style: none; /* IE and Edge */
    scrollbar-width: none; /* Firefox */
  }
  /* } */
}

* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}

body {
  font-family: "Outfit", sans-serif;
}
}

/* clears the ‘X’ from Internet Explorer */
input[type=search]::-ms-clear { display: none; width : 0; height: 0; }
input[type=search]::-ms-reveal { display: none; width : 0; height: 0; }
/* clears the ‘X’ from Chrome */
input[type="search"]::-webkit-search-decoration,
input[type="search"]::-webkit-search-cancel-button,
input[type="search"]::-webkit-search-results-button,
input[type="search"]::-webkit-search-results-decoration { display: none; }