/* breadcrumbs */
.breadcrumbs {
    max-width: 1200px;
    margin-left: 4px;
  }
  .breadcrumbs > *{
    display: inline-block;
    margin-right: 10px;
  }
  .breadcrumbs .crumb:after {
    content: '>';
    margin-left: 10px;
  }
  .breadcrumbs .crumb:last-child:after {
    display: none;
  }
  .breadcrumbs .crumb a {
    color: black;
    text-transform: capitalize;
  }